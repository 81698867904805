import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'
import axiosService from '@/services/AxiosService'
import { AxiosResponse } from 'axios';

const EXCEL_TYPE =  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

export class ExportExcelService<TRecord> {
    private _records: Array<TRecord> = []
    private _workbook: ExcelJS.Workbook;

    constructor (records: Array<TRecord>) {
        this._records = records
        this ._workbook = new ExcelJS.Workbook()
    }


    public async Export2Excel(filename: string, columns: Array<Partial<ExcelJS.Column>>) {
        this._workbook.addWorksheet('data', {views:[{ state: 'frozen', ySplit: 1 }]})
        const worksheet = this._workbook.getWorksheet('data');
        worksheet.columns = columns
        this._records.forEach(r => {
            const rm = this.ExtractProperies(r)
            const row: any =  {}
            rm.forEach((value, key, map) => {
                row[key] = value
            });
            worksheet.addRow(row)
        })
        this.DownloadBlob(await this._workbook.xlsx.writeBuffer(), EXCEL_TYPE, ".xlsx", filename)
    }

    public async Export2Pdf(filename: string, companyIds: Array<string>) {
        axiosService.post('api/companys/communication-pdf', companyIds, { responseType: 'blob'}).then(({ data }) => {
            this.DownloadBlob(data, 'application/pdf', '.pdf', filename)
        })
    }

    public async ExportLead2Pdf(filename: string, leadIds: Array<string>) {
        axiosService.post('api/leads/lead-pdf', leadIds, { responseType: 'blob'}).then(({ data }) => {
            this.DownloadBlob(data, 'application/pdf', '.pdf', filename)
        })
    }

    private ExtractProperies(record: TRecord): Map<string, string> {
       const map = new Map()
       Object.getOwnPropertyNames(record)
            .filter(n => typeof Object.getOwnPropertyDescriptor(record, n) !== 'function')
            .forEach(n => {
                map.set(n, Object.getOwnPropertyDescriptor(record, n)?.value )
            })

        return map
    }

    private DownloadBlob (buffer: ArrayBuffer, cotentType: string, ext: string, fileName: string) {
        const data: Blob = new Blob([buffer], {
            type: cotentType
        })

        FileSaver.saveAs(data, fileName + "_" + new Date().getTime() + ext);
    }
}
